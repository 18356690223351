import { configApi } from "./api";

configApi().then(config => {
    if (config.trackUser) {
        addTracking();
    } else {
        console.log("No Tracking");
    }
});

const addTracking = () => {
    console.log("Track user using hotjar");

    (function (c, s, q, u, a, r, e) {
        c.hj =
            c.hj ||
            function () {
                (c.hj.q = c.hj.q || []).push(arguments);
            };
        c._hjSettings = { hjid: a };
        r = s.getElementsByTagName("head")[0];
        e = s.createElement("script");
        e.async = true;
        e.src = q + c._hjSettings.hjid + u;
        r.appendChild(e);
    })(window, document, "https://static.hj.contentsquare.net/c/csq-", ".js", 5179505);
};
